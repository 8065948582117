export type TPageTitlePart = string | false | undefined | null;

export function getPageTitle(...args: [TPageTitlePart[] | TPageTitlePart, ...rest: TPageTitlePart[]]): string {
	let parts: TPageTitlePart[] = [];

	if (Array.isArray(args[0])) {
		parts = args[0] as TPageTitlePart[];
	} else {
		parts = args.filter((value) => typeof value === 'string');
	}

	parts = parts.filter(Boolean) as string[];

	return ['Paragon+', parts.length > 0 && parts.join(' / ')].filter(Boolean).join(' // ');
}
