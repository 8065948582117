import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { reaction } from 'mobx';
import { observer } from 'mobx-react';

import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import app, { App } from 'src/app';

import 'src/theme/imports.less';
import 'src/theme/variables.less';
import 'src/theme/style.less';

import router from 'src/router';

export interface ILandingPropTypes {
	app: App;
}

function LandingBase(props: ILandingPropTypes): React.ReactNode {
	return (
		<React.StrictMode>
			<IntlProvider {...props.app.models.intl.props}>
				<RouterProvider router={router} />
				<Toaster
					toastOptions={{
						duration: 5000,
						position: 'top-right',
						style: {
							borderRadius: '0',
							background: 'var(--page-color-lightest)',
							color: 'var(--page-color-text)',
						},
					}}
				/>
			</IntlProvider>
		</React.StrictMode>
	);
}

const Landing = observer(LandingBase);

(function (window: Window, document: Document) {
	'use strict';

	window.Brandis = window.Brandis || {};
	window.Brandis.app = window.Brandis.app || app;

	app.initialize();

	window.addEventListener('load', (event) => {
		const rootEl = document.getElementById('b-root');
		const throbberEl = document.getElementById('b-throbber');

		new Promise<void>((resolve, reject) => {
			const rejectTimeoutId = setTimeout(() => reject(new Error('Initlaization failed.')), 25000);

			reaction(
				() => app.isInitialized,
				(isInitiailized, _, r) => {
					if (!isInitiailized) {
						return;
					}

					clearTimeout(rejectTimeoutId);
					resolve();
					r.dispose();
				},
				{
					fireImmediately: true,
				}
			);
		})
			.then(() => {
				if (!!throbberEl) {
					throbberEl.style.opacity = '0';
				}

				return Promise.race([
					new Promise((resolve) => {
						throbberEl?.addEventListener('transitionend', resolve);
					}),
					new Promise((resolve) => {
						setTimeout(resolve, 10000);
					}),
				]);
			})
			.then(() => {
				const root = ReactDOM.createRoot(rootEl as HTMLDivElement);
				root.render(React.createElement(Landing, { app }));

				throbberEl?.remove();
			});
	});
})(window, window.document);
