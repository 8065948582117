import { generatePath as _generatePath, PathParam } from 'react-router';

import _isString from 'lodash/isString';
import _isNil from 'lodash/isNil';

export function generatePath<Path extends string>(
	...args: (Path | { [key in PathParam<Path>]: string | number | null | undefined } | undefined)[]
): string {
	args = args.filter((value) => !_isNil(value));

	return _generatePath(
		`/${args.filter(_isString).join('/').replace(/^\//, '')}`,
		(() => {
			if (!_isString(args[args.length - 1])) {
				return Object.entries(
					args.pop() as { [key in PathParam<Path>]: string | number | null | undefined }
				).reduce((result, [key, value]) => {
					return {
						...result,
						[key]: _isNil(value) ? null : String(value),
					};
				}, {});
			}

			return {} as unknown as { [key in PathParam<Path>]: string | null };
		})()
	);
}
