import * as React from 'react';

export function useBreakpoint(): { isMobile: boolean; isDesktop: boolean } {
	const [isMobile, setIsMobile] = React.useState(false);

	const query = window.matchMedia('screen and (max-width: 900px)');

	query.addEventListener('change', (event) => {
		setIsMobile(event.matches);
	});

	React.useLayoutEffect(() => {
		setIsMobile(query.matches);
	}, [query.matches]);

	return { isMobile, isDesktop: !isMobile };
}
