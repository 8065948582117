import app from 'src/app';

export function getDatesRange(dateBegin: Date | undefined | null, dateEnd: Date | undefined | null): string | null {
	if (!(dateBegin && dateEnd)) {
		return null;
	}

	const isSameMonth = dateBegin.getMonth() === dateEnd.getMonth();
	const isSameYear = dateBegin.getFullYear() === dateEnd.getFullYear();

	return [
		app.models.intl.formatDate(
			dateBegin,
			[isSameMonth && isSameYear ? 'dd' : 'dd MMMM', !isSameYear && 'yyyy'].filter(Boolean).join(' ')
		),
		app.models.intl.formatDate(dateEnd, ['dd MMMM', !isSameYear && 'yyyy'].filter(Boolean).join(' ')),
	].join(' — ');
}
